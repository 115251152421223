define("@woody-lite/addon-tasks/normalizers/task", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SPECIAL_TYPEID = 'KBYR_Task1_ID';
  class TaskNormalizer extends _object.default {
    normalize(properties) {
      delete properties.content;
      if (properties.typeId === SPECIAL_TYPEID) {
        properties.formatId = `${properties.typeId}-${properties.description}`;
      } else {
        properties.formatId = properties.typeId;
      }
      return {
        name: 'task',
        properties
      };
    }
  }
  _exports.default = TaskNormalizer;
});