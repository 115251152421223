define("@woody-lite/core-session/templates/modal-face-recognition-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <WrapperMain as |wrapper|>
  	<wrapper.header @type="modal-primary" as |header|>
  		<header.title @title={{t "label.signature"}} />
  		<header.right as |i|>
  			<i.icon
  				data-id="btnClose"
  				class="icon-close"
  				@onClick={{this.closeModal}}
  				@text={{t "label.header.close"}}
  				@accessibilityText={{t "label.header.close"}}
  			/>
  		</header.right>
  	</wrapper.header>
  	<wrapper.alerter />
  
  	<wrapper.content>
  
  		<Panels::PanelInfo @image={{image-path "banners/error.svg"}} @size="small" @title={{t this.title}} as |panel|>
  			<panel.text>
  				{{t this.text}}
  			</panel.text>
  		</Panels::PanelInfo>
  
  		<div class="set-padding-2 txt-align-c">
  			<Buttons::ButtonDefault @text={{t "label.close"}} @onClick={{this.closeModal}} />
  		</div>
  	</wrapper.content>
  </WrapperMain>
  */
  {
    "id": "Shsvxp+l",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"header\"]],null,[[\"@type\"],[\"modal-primary\"]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,2,[\"title\"]],null,[[\"@title\"],[[28,[37,1],[\"label.signature\"],null]]],null],[1,\"\\n\\t\\t\"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"icon\"]],[[24,\"data-id\",\"btnClose\"],[24,0,\"icon-close\"]],[[\"@onClick\",\"@text\",\"@accessibilityText\"],[[30,0,[\"closeModal\"]],[28,[37,1],[\"label.header.close\"],null],[28,[37,1],[\"label.header.close\"],null]]],null],[1,\"\\n\\t\\t\"]],[3]]]]],[1,\"\\n\\t\"]],[2]]]]],[1,\"\\n\\t\"],[8,[30,1,[\"alerter\"]],null,null,null],[1,\"\\n\\n\\t\"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\\n\\t\\t\"],[8,[39,2],null,[[\"@image\",\"@size\",\"@title\"],[[28,[37,3],[\"banners/error.svg\"],null],\"small\",[28,[37,1],[[30,0,[\"title\"]]],null]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,4,[\"text\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,1],[[30,0,[\"text\"]]],null]],[1,\"\\n\\t\\t\\t\"]],[]]]]],[1,\"\\n\\t\\t\"]],[4]]]]],[1,\"\\n\\n\\t\\t\"],[10,0],[14,0,\"set-padding-2 txt-align-c\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,5],null,[[\"@text\",\"@onClick\"],[[28,[37,1],[\"label.close\"],null],[30,0,[\"closeModal\"]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"header\",\"i\",\"panel\"],false,[\"wrapper-main\",\"t\",\"panels/panel-info\",\"image-path\",\"div\",\"buttons/button-default\"]]",
    "moduleName": "@woody-lite/core-session/templates/modal-face-recognition-error.hbs",
    "isStrictMode": false
  });
});