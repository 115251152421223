define("@woody-lite/addon-webcomponents/decorators/ce-storage", ["exports", "@ember/service", "@ember/application", "@ember/object", "ember-utils/utils/primitive/merge", "@woody-lite/addon-webcomponents/utils/wc-bundles-loader"], function (_exports, _service, _application, _object, _merge, _wcBundlesLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CeStorageLoader;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function CeStorageLoader(Target) {
    var _class, _descriptor, _descriptor2;
    const Klass = (_class = class CeStorageLoaderDecorator extends Target {
      constructor() {
        super(...arguments);
        _initializerDefineProperty(this, "assetLoader", _descriptor, this);
        _initializerDefineProperty(this, "assetMap", _descriptor2, this);
      }
      get ceStorage() {
        this._ceStorage = this._ceStorage || customElements.get('bbva-ce-storage');
        return this._ceStorage;
      }
      async beforeModel() {
        try {
          await this.loadWebComponentsBundles();
        } catch {
          //
        }
        return super.beforeModel(...arguments);
      }
      async loadWebComponentsBundles() {
        const webComponentsBundlesLoader = new _wcBundlesLoader.default(this.assetLoader, this.assetMap, this.basePath);

        // import polyfills
        await webComponentsBundlesLoader.loadWebcomponentsPolyfills();
      }
      setStorageItem(key, value) {
        this.ceStorage.delete(key);
        this.ceStorage.set(key, value);
      }
      getStorageItem(key) {
        return this.ceStorage.get(key);
      }
      setDmAdapter(instanceDmAdapter) {
        const engineMountPoint = (0, _application.getOwner)(this).mountPoint;
        const woodyDmAdapterName = `wc-dm-adapter-${engineMountPoint}`;
        const storeDmAdapter = this.getStorageItem(woodyDmAdapterName);
        const adapter = storeDmAdapter ? (0, _merge.default)(storeDmAdapter, instanceDmAdapter) : instanceDmAdapter;
        this.setStorageItem('woodyDmAdapterName', woodyDmAdapterName);
        this.setStorageItem(woodyDmAdapterName, adapter);
      }
      async willTransition(transition) {
        var _transition$targetNam;
        if (super.willTransition) {
          await super.willTransition(...arguments);
        }
        const targetRouteName = (_transition$targetNam = transition.targetName) === null || _transition$targetNam === void 0 || (_transition$targetNam = _transition$targetNam.split('.')) === null || _transition$targetNam === void 0 ? void 0 : _transition$targetNam[0];
        const engineMountPoint = (0, _application.getOwner)(this).mountPoint;
        if (engineMountPoint !== targetRouteName) {
          const woodyDmAdapterName = `wc-dm-adapter-${engineMountPoint}`;
          const storeDmAdapter = this.getStorageItem(woodyDmAdapterName);
          if (storeDmAdapter) {
            this.ceStorage.delete(woodyDmAdapterName);
          }
        }
      }
    }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "assetLoader", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "assetMap", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _class);
    return Klass;
  }
});