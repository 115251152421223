define("@woody-mrs-potato/addon-footer-navbar/footer-actions/action", ["exports", "@ember/debug", "@ember/object"], function (_exports, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FooterAction extends _object.default {
    perform() {
      (false && !(false) && (0, _debug.assert)('"perform" must be implemented', false));
    }
  }
  _exports.default = FooterAction;
});