define("@woody-mrs-potato/addon-ember-modal-service/services/modal", ["exports", "@ember/service", "@ember/array", "@ember/application", "@ember/utils", "@ember/object/evented"], function (_exports, _service, _array, _application, _utils, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ModalService extends _service.default.extend(_evented.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "content", (0, _array.A)());
    }
    open(name) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const ModalModel = (0, _application.getOwner)(this).factoryFor('model:modal');
      const model = ModalModel.create({
        name,
        options
      });

      // If the modal is already opened, reject it
      if (this.isOpened(name)) {
        model.reject(null, `Modal: '${model.fullname}' is already opened`);
      } else {
        // Add new modal.
        this.content.addObject(model);
      }
      this.trigger('open', model);
      model.promise.catch(() => {}).finally(() => {
        this.trigger('close', model);
      });
      return model.promise;
    }
    _closeByModel(model) {
      // Remove from DOM
      this.content.removeObject(model);
    }
    close(key, value) {
      let filter = this.content;
      if (typeof key === 'function') {
        filter = this.content.filter(key);
      } else if (key && value) {
        filter = this.content.filterBy(key, value);
      }
      filter.forEach(modal => {
        modal.reject(null, `Modal: closing '${modal.fullname}'`);
      });
    }
    isOpened(name) {
      const filter = this.content.findBy('name', name);
      return !(0, _utils.isEmpty)(filter);
    }
  }
  _exports.default = ModalService;
});