define("@woody-mrs-potato/addon-footer-navbar/components/footer-navbar", ["exports", "@ember/component", "@glimmer/component", "@ember/application", "@ember/object", "@ember/string", "@ember/application/instance", "@ember/template-factory"], function (_exports, _component, _component2, _application, _object, _string, _instance, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Navigations::NavigationTabBar
  	data-id="footerTabBar"
  	@accessibilityText={{t "accessibility.navBarBottom"}}
  	as |navigation|
  >
  	{{#each this.footerItems as |item|}}
  		<navigation.icon
  			data-id={{item.dataId}}
  			@icon={{item.icon}}
  			@text={{t item.label}}
  			@onClick={{fn this.dispatchAction item}}
  			@active={{item.isActive}}
  			as |icon|
  		>
  			{{#let (await item.notifications) as |notifications|}}
  				{{#if (gt notifications 0)}}
  					<icon.badge
  						@count={{notifications}}
  						@accessibilityText={{t
  							"accessibility.hasNotifications"
  							count=notifications
  						}}
  					/>
  				{{/if}}
  			{{/let}}
  		</navigation.icon>
  	{{/each}}
  </Navigations::NavigationTabBar>
  */
  {
    "id": "/XPOcGKC",
    "block": "[[[8,[39,0],[[24,\"data-id\",\"footerTabBar\"]],[[\"@accessibilityText\"],[[28,[37,1],[\"accessibility.navBarBottom\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"footerItems\"]]],null]],null],null,[[[1,\"\\t\\t\"],[8,[30,1,[\"icon\"]],[[16,\"data-id\",[30,2,[\"dataId\"]]]],[[\"@icon\",\"@text\",\"@onClick\",\"@active\"],[[30,2,[\"icon\"]],[28,[37,1],[[30,2,[\"label\"]]],null],[28,[37,4],[[30,0,[\"dispatchAction\"]],[30,2]],null],[30,2,[\"isActive\"]]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,6],[[30,2,[\"notifications\"]]],null]],[[[41,[28,[37,8],[[30,4],0],null],[[[1,\"\\t\\t\\t\\t\\t\"],[8,[30,3,[\"badge\"]],null,[[\"@count\",\"@accessibilityText\"],[[30,4],[28,[37,1],[\"accessibility.hasNotifications\"],[[\"count\"],[[30,4]]]]]],null],[1,\"\\n\"]],[]],null]],[4]]],[1,\"\\t\\t\"]],[3]]]]],[1,\"\\n\"]],[2]],null]],[1]]]]]],[\"navigation\",\"item\",\"icon\",\"notifications\"],false,[\"navigations/navigation-tab-bar\",\"t\",\"each\",\"-track-array\",\"fn\",\"let\",\"await\",\"if\",\"gt\"]]",
    "moduleName": "@woody-mrs-potato/addon-footer-navbar/components/footer-navbar.hbs",
    "isStrictMode": false
  });
  let FooterNavbarComponent = _exports.default = (_class = class FooterNavbarComponent extends _component2.default {
    get config() {
      return this._config;
    }
    set config(value) {
      this._config = value;
    }
    get footerItems() {
      const order = this.config;
      return order === null || order === void 0 ? void 0 : order.map(item => this.appOwner.lookup(`footer-action:${(0, _string.dasherize)(item)}`));
    }
    constructor() {
      var _this$appOwner$resolv;
      super(...arguments);
      _defineProperty(this, "_config", void 0);
      _defineProperty(this, "appOwner", this.getRegistrationConfig());
      this.config = (_this$appOwner$resolv = this.appOwner.resolveRegistration('config:environment')) === null || _this$appOwner$resolv === void 0 ? void 0 : _this$appOwner$resolv['addon-footer-navbar'];
    }
    getRegistrationConfig() {
      const appInstance = (0, _application.getOwner)(this);
      const isEngine = !(appInstance instanceof _instance.default);
      if (isEngine && appInstance.base) {
        return (0, _application.getOwner)(appInstance.base);
      }
      return appInstance;
    }
    dispatchAction(footerAction) {
      const route = (0, _string.dasherize)(footerAction.to).replace(/\//g, '-');
      footerAction.perform(route);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "dispatchAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dispatchAction"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FooterNavbarComponent);
});