define("@woody-lite/core-session/enaxs/granting-tickets-oauth", ["exports", "@woody-lite/core-network/resources/enax", "@woody-mrs-potato/core-http/services/http", "ember-utils/utils/math/xor", "@woody-lite/core-session/decorators/headers-bbva"], function (_exports, _enax, _http, _xor, _headersBbva) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let GrantingTicketsOAuthEnax = _exports.default = (_dec = (0, _headersBbva.default)('enax'), _dec(_class = class GrantingTicketsOAuthEnax extends _enax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "smc", 'SMC201400324');
      _defineProperty(this, "endpoint", '/TechArchitecture/grantingTicketsOauth/V01');
      _defineProperty(this, "credentials", 'include');
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_ONLY);
      _defineProperty(this, "data", {
        grant_type: 'client_credentials'
      });
      _defineProperty(this, "contentType", 'application/x-www-form-urlencoded');
      _defineProperty(this, "acceptType", null);
    }
    get authorization() {
      const {
        userId,
        M1,
        M2
      } = this;
      const key = (0, _xor.default)(M1, M2);
      return btoa(`${userId}:${key}`);
    }
    before() {
      this.merge({
        headers: {
          authorization: `Basic ${this.authorization}`
        }
      });
      return super.before(...arguments);
    }
  }) || _class);
});