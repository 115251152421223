define("@woody-lite/addon-utils/utils/format-address", ["exports", "@ember/utils", "@ember/object", "ember-utils/utils/strings/ucwords"], function (_exports, _utils, _object, _ucwords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAddress = formatAddress;
  function setFirstLine(address, complete) {
    const streetType = _prettify((0, _object.get)(address, 'streetType.name') || (0, _object.get)(address, 'streetTypeName')) || null;
    const streetName = _prettify((0, _object.get)(address, 'street') || (0, _object.get)(address, 'streetName') || (0, _object.get)(address, 'name'));
    const streetNumber = (0, _object.get)(address, 'streetNumber') ? `${(0, _object.get)(address, 'streetNumber')}`.replace(/^0+/, '') : null;
    const firstLineBase = [streetType, streetName, streetNumber].filter(Boolean).join(' ');
    const firstLineAdditional = complete ? additionalAddress(address) : null;
    return firstLineAdditional ? [firstLineBase, firstLineAdditional].filter(Boolean).join(', ') : firstLineBase;
  }
  function setSecondLine(address) {
    const city = _prettify((0, _object.get)(address, 'city'));
    const province = _prettify((0, _object.get)(address, 'province.name')) || _prettify((0, _object.get)(address, 'province'));
    const zipCode = (0, _object.get)(address, 'zipCode');
    let provinceWithCity = null;
    if (city && province) {
      provinceWithCity = [city, province].filter(Boolean).join(', ');
    }
    if (zipCode && provinceWithCity) {
      return [zipCode, provinceWithCity].filter(Boolean).join(' ');
    }
    if (zipCode || city) {
      return [zipCode, city].filter(Boolean).join(' ');
    }
    return null;
  }
  function additionalAddress(address) {
    const {
      stairs,
      floor,
      door,
      stairText,
      floorText,
      doorText,
      additionalInformation
    } = (0, _object.getProperties)(address, ['stairs', 'floor', 'door', 'stairText', 'floorText', 'doorText', 'additionalInformation']);
    const hasAnyAdditionalAddress = stairs && stairText || floor && floorText || door && doorText;
    if (hasAnyAdditionalAddress) {
      return additionalAddressFormat({
        stairs,
        stairText,
        floor,
        floorText,
        door,
        doorText
      });
    }
    return additionalInformation;
  }
  function additionalAddressFormat(_ref) {
    let {
      stairs,
      stairText,
      floor,
      floorText,
      door,
      doorText
    } = _ref;
    const arrAdditionalAddress = [];
    if (stairs) {
      arrAdditionalAddress.push(`${stairText} ${stairs}`);
    }
    if (floor) {
      arrAdditionalAddress.push(`${floorText} ${floor}`);
    }
    if (door) {
      arrAdditionalAddress.push(`${doorText} ${door}`);
    }
    return [...arrAdditionalAddress].join(', ');
  }
  function _prettify(value) {
    if (!value) {
      return null;
    }
    return (0, _ucwords.default)(value.toLowerCase());
  }
  function formatAddress(address) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      split: false,
      complete: false
    };
    if (address && ((0, _object.get)(address, 'streetName') || (0, _object.get)(address, 'name'))) {
      if ((0, _utils.typeOf)(options) !== 'object') {
        options = {
          split: true
        };
      }
      const firstLine = setFirstLine(address, options.complete);
      const secondLine = setSecondLine(address);
      if (options.split) {
        return {
          firstLine,
          secondLine
        };
      }
      return [firstLine, secondLine].filter(Boolean).join(', ');
    }
    return null;
  }
});