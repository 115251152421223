define("@woody-lite/core-session/utils/initials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initials;
  function getFirstLetter(name) {
    return name.charAt(0);
  }
  function initials(name, surname) {
    if (name) {
      const secondName = getFirstLetter(surname || '');
      return `${getFirstLetter(name)}${secondName}`;
    }
    return null;
  }
});