define("@woody-lite/core-session/components/modal-pinpositions", ["exports", "@ember/object", "@ember/service", "@woody-lite/core-session/templates/modal-pinpositions", "@woody-lite/core-foundation/components/modal", "@glimmer/tracking"], function (_exports, _object, _service, _modalPinpositions, _modal, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DEFAULT_IMAGE = 'banners/mobile-sign-in.svg';
  const MAX_ATTEMPTS = 3;
  const ERROR_MESSAGES = ['01237066'];
  let ModalPinpositions = _exports.default = (_class = class ModalPinpositions extends _modal.default {
    init() {
      super.init(...arguments);
      const [firstPosition, secondPosition] = this.model.options.resource.headers.authenticationchallenge.split(',').map(Number);
      this.firstPosition = firstPosition;
      this.secondPosition = secondPosition;
    }
    constructor() {
      var _this$veil;
      super(...arguments);

      // TODO: Remove into DB-230740
      _initializerDefineProperty(this, "alerter", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "security", _descriptor4, this);
      _initializerDefineProperty(this, "veil", _descriptor5, this);
      _initializerDefineProperty(this, "image", _descriptor6, this);
      _initializerDefineProperty(this, "attempts", _descriptor7, this);
      _initializerDefineProperty(this, "error", _descriptor8, this);
      _initializerDefineProperty(this, "noMoreAttempts", _descriptor9, this);
      _initializerDefineProperty(this, "firstPosition", _descriptor10, this);
      _initializerDefineProperty(this, "secondPosition", _descriptor11, this);
      _initializerDefineProperty(this, "errorDuplicatedPassword", _descriptor12, this);
      _defineProperty(this, "layout", _modalPinpositions.default);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    async submitPositions(positions) {
      await this.submit(positions);
    }
    async submit(positions) {
      try {
        this.veil.add();
        const options = (0, _object.get)(this, 'model.options');
        const resource = options.resource;
        const pinValue = `${positions[0]}${positions[1]}`;
        const value = await this.security.encrypt(`${pinValue}FFFFFFFFFFFFFF`);

        // Get promise.
        (0, _object.set)(resource, 'headers.authenticationdata', `pinpositions=${value}`);
        (0, _object.set)(resource, 'headers.authenticationchallenge', this.model.options.resource.headers.authenticationchallenge);
        const data = await resource.retry();
        return this.close(data);
      } catch (e) {
        return this.hasErrorMessage(e);
      } finally {
        this.key = '';
        this.veil.remove();
      }
    }
    hasErrorMessage(e) {
      var _e$data;
      if (ERROR_MESSAGES.includes(e === null || e === void 0 || (_e$data = e.data) === null || _e$data === void 0 ? void 0 : _e$data['error-code'])) {
        this.errorDuplicatedPassword = true;
      } else {
        this.error = true;
        this.attempts--;
        if (this.attempts <= 0) {
          this.noMoreAttempts = true;
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "security", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "image", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_IMAGE;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "attempts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return MAX_ATTEMPTS;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "noMoreAttempts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "firstPosition", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "secondPosition", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "errorDuplicatedPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submitPositions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submitPositions"), _class.prototype), _class);
});