define("@woody-lite/core-semaas/services/semaas-promo-dispatcher", ["exports", "@woody-mrs-potato/addon-ember-collector-dispatcher/services/dispatcher", "@ember/service", "@woody-mrs-potato/core-http/utils/fetchify", "@ember/application", "@ember/object", "@woody-mrs-potato/core-http/errors/fetch"], function (_exports, _dispatcher, _service, _fetchify, _application, _object, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SemaasPromoDispatcherService = _exports.default = (_dec = (0, _service.inject)('semaas-promo-collector'), _class = class SemaasPromoDispatcherService extends _dispatcher.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "collector", _descriptor2, this);
      _defineProperty(this, "currentSession", null);
      _defineProperty(this, "maxTimeout", this.config.timeout);
      _defineProperty(this, "maxConcurrent", this.config.chunkSize);
      _defineProperty(this, "metricSet", 'promo');
    }
    get config() {
      const {
        semaas
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return semaas;
    }
    setCurrentSession(currentSession) {
      this.currentSession = currentSession;
    }
    async getSemaasSession() {
      var _this$currentSession;
      if (!((_this$currentSession = this.currentSession) !== null && _this$currentSession !== void 0 && _this$currentSession.isSynchronized)) {
        return null;
      }
      return await this.provider.pull('semaas', 'getSession', this.currentSession);
    }
    async dispatch(items) {
      const session = await this.getSemaasSession();
      if (!session) {
        await this.stop();
        return items;
      }
      try {
        await this.sendMeasurements(session, items);
        return [];
      } catch (e) {
        if (!(e instanceof _fetch.default)) {
          await this.stop();
        }
        return [];
      }
    }
    sendMeasurements(session, measurements) {
      const options = {
        url: `${this.config.hosts.mu}/v0/ns/${this.config.promoNamespace}/metric-sets/${this.metricSet}:addMeasurements`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${(0, _object.get)(session, 'accessToken')}`,
          'content-type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify({
          metrics: measurements
        })
      };
      return new _fetchify.default(options).fetch();
    }
    createMeasurement(body) {
      const factory = (0, _application.getOwner)(this).factoryFor('model:promo');
      const measurement = factory.create(body);
      const obj = measurement.toJSON();
      this.collector.push(obj);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "collector", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});