define("@woody-lite/core-traceability/decorators/tracking-data-for", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = trackingDataFor;
  function trackingDataForFn(routeName) {
    var _route$trackingData;
    if (!routeName) {
      return null;
    }
    const route = (0, _application.getOwner)(this).lookup(`route:${routeName}`, {
      instantiate: false
    });
    return route === null || route === void 0 || (_route$trackingData = route.trackingData) === null || _route$trackingData === void 0 ? void 0 : _route$trackingData.call(route);
  }
  function trackingDataFor(routeName) {
    return (/* Target, propertyName, descriptor */
    ) => {
      return {
        get() {
          return this.__trackingDataFor__ || trackingDataForFn.call(this, routeName);
        },
        set(value) {
          this.__trackingDataFor__ = value;
        }
      };
    };
  }
});